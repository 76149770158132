import React from 'react'

const CardProfile = (props) => {
  return (
    <div className='max-w-[450px] w-[450px] max-h-[220px] h-[220px] flex flex-col justify-end pb-9  pl-[32px] gap-[10px] bg-[#231F42] rounded-lg max-sm:w-full max-xl:min-w-fit max-sm:px-8 max-2xl:h-fit max-2xl:gap-2 max-2xl:justify-center max-2xl:py-[18px] '>
        <h6 className='header6 max-sm:text-[16px]'>{props.name}</h6>
        <p className='paragraph max-sm:text-[12px]'>{props.job}</p>
    </div>
  )
}

export default CardProfile