import React, { Component,useState } from "react";
import { NavLink } from "react-router-dom";
import {TbMenu2} from "react-icons/tb"
import {RiCloseLine} from "react-icons/ri"

const Navbar = () =>{
    const [show, setShow] =useState(false)
    return(
        <div>
            <div className="flex justify-between py-6 items-center fixed bg-white z-10 w-full px-[10%] max-sm:px-[%5]">
                <div>
                    <h1 className="text-[22px] font-poppins font-bold">Tag Team.</h1>
                </div>
                <button className="md:hidden" onClick={()=>setShow(!show)}>{show ? <RiCloseLine fontSize={22}/>:<TbMenu2 fontSize={22}/>}</button>
                <div className="flex gap-8 max-md:hidden">
                    <NavLink className={`paragraph`} to="/Features">Features</NavLink>
                    <NavLink className={`paragraph`} to="/Product">Product</NavLink>
                    <NavLink className={`paragraph`} to="/Services">Services</NavLink>
                    <NavLink className={`paragraph`} to="/Contact">Contact</NavLink>
                </div>
                <div className={`flex flex-col absolute gap-10 h-[100vh] w-full left-0 top-16 items-center transition-all duration-300 z-10 bg-white md:hidden  ${show ? "translate-x-0":"translate-x-full"}`}>
                    <NavLink className="paragraph" to="/Features">Features</NavLink>
                    <NavLink className="paragraph" to="/Product">Product</NavLink>
                    <NavLink className="paragraph" to="/Services">Services</NavLink>
                    <NavLink className="paragraph" to="/Contact">Contact</NavLink>
                </div>
                <div className="max-md:hidden">
                    <button></button>
                    <button className="paragraph py-3 px-6 border">Get Strated</button>
                </div>
            </div>
        </div>
    )
}


export default Navbar