import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import Features from "./Pages/Features";
import Product from "./Pages/Product";
import Services from "./Pages/Services";
import Contact from "./Pages/Contact";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<div><Home/></div>}/>
        <Route path="/Features" element={<div><Features/></div>}/>
        <Route path="/Product" element={<div><Product/></div>}/>
        <Route path="/Services" element={<div><Services/></div>}/>
        <Route path="/Contact" element={<div><Contact/></div>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
