import React, { useState } from "react";

const CardInterest = (props) =>{
    const [toogle, setToogle] = useState(true)
    return(
        <button onClick={()=>setToogle(!toogle)} >
            <div className={`border w-fit py-4 px-6 max-sm:py-2 max-sm:text-[13px] max-sm:px-4 hover:text-white hover:border-white  transition-all duration-300 ${toogle ? 'border-[#656565] text-[#656565]' : 'border-white text-white'}`}>
                <p>{props.insterest}</p>
            </div>
        </button>
    )
}

export default CardInterest