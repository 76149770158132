import React, {Component} from "react";

class Features extends Component{
    render(){
        return(
            <div>
                Features
            </div>
        )
    }
}

export default Features