import React, {Component} from "react";

class Product extends Component{
    render(){
        return(
            <div>
                Product
            </div>
        )
    }
}

export default Product