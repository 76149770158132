import React, {Component} from "react";
import Navbar from "../Component/Navbar";
import heroRight from "../Assets/hero-right.svg"
import {TbArrowUpRight, TbBrandDribbble, TbBrandGithub, TbBrandBehance, TbBrandReact, TbBrandFigma, TbDeviceDesktop, TbVectorBezier} from "react-icons/tb"
import img1 from "../Assets/Rectangle 44.png"
import img2 from "../Assets/Rectangle 46.png"
import img3 from "../Assets/Rectangle 47.png"
import CardProfile from "../Component/CardProfile";
import Background from "../Assets/Rectangle 51.svg"
import CardInterest from "../Component/CardInterest";
import axios from "axios";
class Home extends Component{
    state = {
        profile: [],
        formBlogPost: {
          id: 1,
          name: '',
          email: '',
          project:'',
          userId: 1
        }
      }
      getPostAPI = () => {
        axios.get('http://localhost:3004/profile?_sort=id&_order=desc')
          .then((result) => {
            console.log(result.data)
            this.setState({
              profile: result.data
            })
          })
          .catch((error) => {
            console.log('Error:', error)
          })
      }
      
      postDataToAPI = () => {
        axios.post('http://localhost:3004/profile', this.state.formBlogPost)
          .then((res) => {
            console.log(res)
            this.getPostAPI()
          })
          .catch((error) => {
            console.log('Error:', error)
          })
      }
      
      handleRemove = (data) => {
        axios.delete(`http://localhost:3004/profile/${data}`)
          .then((res) => {
            this.getPostAPI()
          })
          .catch((error) => {
            console.log('Error:', error)
          })
      }
      
      componentDidMount() {
        this.getPostAPI()
      }
      
      handleFormChange = (event) => {
        let formBlogPostNew = { ...this.state.formBlogPost }
        let timestamp = new Date().getTime()
        formBlogPostNew['id'] = timestamp
        formBlogPostNew[event.target.name] = event.target.value
        this.setState({
          formBlogPost: formBlogPostNew
        }, () => {
          // console.log("Value Object Form Blog Post:", this.state.formBlogPost)
        })
      }
      
      handleSubmit = () => {
        this.postDataToAPI()
      }
      
    render(){
        return(
            <div className="relative">
                <Navbar/>
                <div className="sm:pt-[150px] max-sm:pt-[100px] px-[10%] max-sm:px-[%5] relative">
                    <div className=" flex justify-between sm:mb-[85px]">
                        <div className="max-sm:flex max-sm:flex-col max-sm:items-center">
                            <h2 className="header2 max-sm:text-[28px] max-sm:leading-9 max-sm:text-center max-sm:font-semibold sm:w-[650px]">We make apps and webs exclusively.</h2>
                            <p className="text-[20px] font-normal my-7 max-sm:text-center max-sm:mt-[18px] max-sm:mb-[21px]">Evolve your design and scale up your brand looks 👋</p>
                            <div className=" w-full flex max-sm:flex-col max-sm:items-center gap-[18px]">
                                <button className={`px-8 py-4 rounded-md flex items-center max-sm:w-fit max-sm:py-3 max-sm:px-6 bg-[#1D1A30] text-white gap-[18px]`}>See Our Work <TbArrowUpRight/> </button>
                                <button className={`max-sm:hidden text-center px-8 py-4`}>Who Are We</button>
                            </div>
                            <p className="mt-[56px]">Our Portofolio</p>
                            <div className="text-[32px] flex gap-10 mt-[26px]">
                                <TbBrandDribbble/>
                                <TbBrandGithub/>
                                <TbBrandBehance/>
                            </div>
                        </div>
                        <div className="max-2xl:hidden">
                            <img src={heroRight} alt="" />
                        </div>
                    </div>
                    <div className="text-white sm:bg-gradient-to-r from-[#1D1A30] via-[#312B54] to-[#2D284E] max-sm:bg-[#1D1A30] flex sm:py-11 sm:px-[72px] justify-between rounded-lg max-sm:flex-col max-sm:items-center max-2xl:px-6 max-2xl:py-[26px] max-2xl:gap-6 max-sm:mt-[38px] max-sm:rounded-xl max-2xl:flex-col max-2xl:items-center">
                        <div>
                            <h5 className="header5 sm:mb-6 max-sm:text-[16px] max-2xl:text-center">Meet the Team 😃</h5>
                            <p className="paragraph max-sm:text-[13px] max-2xl:text-center">Get to know the faces behind the amazing work.</p>
                        </div>
                        <div className="flex gap-10  max-2xl:flex-col">
                            <CardProfile name="Ikhsan Assidiqie" job="UI/UX Designer"/>
                            <CardProfile name="Dio Irsaputra Siregar" job="Front-End Developer"/>
                        </div>
                    </div>
                    <div className="w-full mt-[62px] flex  gap-7 max-2xl:flex-col">
                        <img className="2xl:w-[70%]" src={img1} alt="" />
                        <div className="flex flex-col justify-between max-2xl:gap-7">
                            <img src={img2} alt="" />
                            <img src={img3} alt="" />
                        </div>
                    </div>
                    <div className="flex sm:mt-[62px] max-2xl:flex-col gap-7 max-sm:mt-[62px] max-2xl:mb-10 ">
                        <div className="max-w-[850px] rounded-3xl w-full border sm:bg-gradient-to-r from-[#1D1A30] via-[#312B54] to-[#2D284E] max-sm:bg-[#1D1A30] sm:p-[72px] text-white max-sm:p-7 max-sm:pb-20 max-sm:relative">
                            <div className="flex justify-between items-center max-sm:flex-col ">
                                <h5 className="header5 max-sm:text-sm">What We Offer</h5>
                                <div className="flex text-[24px] gap-6  max-sm:absolute max-sm:bottom-10" >
                                    <TbBrandReact/>
                                    <TbBrandFigma/>
                                </div>
                            </div>
                            <div className="flex justify-between sm:mt-[56px] max-2xl:flex-col max-2xl:items-center max-2xl:gap-4">
                                <div className="sm:w-[320px] flex flex-col max-2xl:items-center">
                                    <TbDeviceDesktop fontSize={36}/>
                                    <div className="sm:mt-8">
                                        <h6 className="header6 sm:mb-3 max-sm:text-[16px] max-sm:text-center">Website Development</h6>
                                        <p className="paragraph max-sm:text-center max-sm:text-[12px] max-sm:font-normal">Unlock the Potential of Your Website with Our Elegant and Exclusive Design Services!</p>
                                    </div>
                                </div>
                                <div className="sm:w-[320px] flex flex-col max-2xl:items-center">
                                    <TbVectorBezier fontSize={36}/>
                                    <div className="sm:mt-8">
                                        <h6 className="header6 sm:mb-3 max-sm:text-center max-sm:text-[16px]">UI/UX Design</h6>
                                        <p className="paragraph max-sm:text-center max-sm:text-[12px] max-sm:font-normal">Make Your Website and Apps Pop with Our Top-Notch UI/UX Design Services!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="sm:p-[72px] relative bg-white max-sm:p-[26px] max-sm:pb-[78px] rounded-3xl">
                            <h6 className="header6">Our Little Background 👋</h6>
                            <p>Once upon a time, two bright students who loved UI/UX and web programming had a brilliant idea to start a design service agency. They started from scratch, fueled by passion and creativity, and now here we are 😀And many more stories to be told!</p>
                            <p className="absolute bottom-16 max-2xl:bottom-[26px]">Indonesia, 2023</p>
                        </div>
                    </div>
                    <div className="flex max-2xl:flex-col sm:mt-[104px] justify-between items-center text-white">
                        <div className=" sm:w-[515px] ">
                            <h3 className="header3 max-2xl:text-[36px] max-2xl:leading-10 max-2xl:mb-4 ">Have a project?<br/> We would love to help.</h3>
                        </div>
                        <div className="sm:w-[600px] sm:h-[580px] w-full bg-black sm:px-[52px] sm:py-[42px] max-sm:p-7">
                            <p >I'm interested in..</p>
                            <div className="flex flex-wrap gap-[18px] sm:mt-8 max-2xl:mt-3">
                                <CardInterest insterest="UI/UX Designer"/>            
                                <CardInterest insterest="Website"/>            
                                <CardInterest insterest="Mobile Application"/>            
                                <CardInterest insterest="Web Development"/>            
                                <CardInterest insterest="Other"/>            
                            </div>
                            <div className=" sm:mt-[50px] flex flex-col gap-9 max-sm:mt-7">
                                <input type="text" name="name" placeholder="Full Name" className="bg-black sm:w-full sm:pb-3 outline-none border-b-2 border-[]" onChange={this.handleFormChange}/>
                                <input type="text" name="email" placeholder="Active Email" className="bg-black sm:w-full sm:pb-3 outline-none border-b-2" onChange={this.handleFormChange}/>
                                <textarea type="text" name="project" placeholder="Tell us about your project" className=" bg-black overflow-hidden sm:w-full sm:pb-3 outline-none border-b-2 h-10" onChange={this.handleFormChange}></textarea>
                                <button type="submit" className="w-fit sm:py-6 py-3" onClick={this.handleSubmit} >Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-blue-900 absolute sm:h-[1080px] w-full max-sm:h-[870px] sm:bottom-[-200px] max-2xl:bottom-[-100px] z-[-10] ">  
                </div>
            </div>
        )
    }
}

export default Home